/* temp_stylePlugin:src/css/appointment.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.appointment-tiptap-editor p {
  font-size: 16px;
}
.appointment-tiptap-editor ul {
  font-size: 16px;
}

/* temp_stylePlugin:src/views/calendar/css/full-calendar-section.css */
.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
  top: 50px;
}
.fc .fc-col-header-cell-cushion {
  font-weight: normal;
  font-size: 18px;
  padding: 10px 0px;
}
.fc-v-event {
  background-color: unset;
  border: unset;
}

/* temp_stylePlugin:src/views/kanban/views/details/views/gantt/css/gantt.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.gantt-wrapper {
  height: 100%;
}
.gantt-wrapper .gantt-container {
  height: 100%;
}
.gantt-wrapper .gantt-container .popup-wrapper {
  background-color: transparent;
}
.gantt-wrapper .gantt-container .bar-group {
}
.gantt-wrapper .gantt-container .bar-group .bar {
  fill: #D9D9D9;
}
.gantt-wrapper .gantt-container .bar-group .bar-progress {
  fill: #778082;
}
.gantt-wrapper .gantt-container .bar-group .bar-label {
  fill: #000;
}

/* temp_stylePlugin:src/css/vacation.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.dialog-body.animated.relative.w-full.max-w-lg.animated__fadeInUp {
  border-radius: 0.375rem;
}

/*# sourceMappingURL=../../modules/project/dist/index.css.map */